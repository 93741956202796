import {z} from "zod";

import {baseApiPostFetcher} from "@/lib/api/baseInstances/bryaPublicApiFetcher";
import {ProspectMailingListRequestSchema} from "@/lib/formValidators/prospect/prospectMailingListSchema";

const prospectMailingListPostApiPath: string = process.env.NEXT_PUBLIC_BRYA_API_MAILING_LIST_CREATE_PATH? process.env.NEXT_PUBLIC_BRYA_API_MAILING_LIST_CREATE_PATH : '';

type CreateProspectMailingListType = z.infer<typeof ProspectMailingListRequestSchema>;
export async function createMailingListAPi(apiPayload: CreateProspectMailingListType) : Promise<any>  {

    console.log("Ready to Post Create MailingList",prospectMailingListPostApiPath );

    return await baseApiPostFetcher(prospectMailingListPostApiPath, apiPayload);

}