'use client'
import {FieldErrors, useForm} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {z} from 'zod';
import React, {JSX, useState} from "react";
import {ProspectMailingListRequestSchema} from "@/lib/formValidators/prospect/prospectMailingListSchema";
import {createMailingListAPi} from "@/lib/api/apiPosts/prospect/prospectPostAPI";

//we should here call like API or something...

export default function FooterSubscribeComponent(): JSX.Element {
    const [defaultLoaded, setDefaultLoaded] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [subscribedFlag, setSubscribedFlag] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState(undefined);
    type formData = z.infer<typeof ProspectMailingListRequestSchema>;

    const  {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<formData>({ resolver: zodResolver(ProspectMailingListRequestSchema) });

    if (!defaultLoaded) {
        reset( {tenantUuid: 'BRYA', mailListTypeCode: 'GENERAL_MAIL_LIST', entryMethod: 'CUSTOMER_ENTERED',nameUnknownFlag: true, firstName: '', lastName: '' })
        setDefaultLoaded(true);
    }


    //we should here call like API or something...
    const submitData =  (data: formData)  => {
        console.log(data);
        setIsFormSubmitting(true);
        createMailingListAPi(data).then((value) => {

            if (value && value.validated) {
                setSubscribedFlag(true);

            }

        })
    }

    const onError = (errors: FieldErrors<formData>) => {
        console.log("Form Errors", errors)
        let errorMessageElement = document.getElementById('pageErrorMessage')
        if (errorMessageElement && errors.formErrors?.message) {
            errorMessageElement.innerText = errors.formErrors?.message;
            errorMessageElement.hidden = false;
        }
    }

    return(
        <div className="mt-8 xl:mt-0">
            <h3 className="text-base font-medium text-white">Subscribe to our mailing list</h3>
            <p className="mt-4 text-base text-gray-300">
                The latest news, articles, and money saving tips, sent to your inbox.
            </p>
            <form id={"form-footerSubscribe"} onSubmit={handleSubmit(submitData, onError)}>
                <div className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                    Email address
                </label>
                    {!subscribedFlag && (
                <input
                    id={'emailAddress'}
                    autoComplete="email"
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                    placeholder="Enter your email"
                    {...register('emailAddress')}
                />
                    )}
                    {subscribedFlag && (
                <div>Success!  You have been subscribed</div>)}
                    {!subscribedFlag && (
                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    {!isFormSubmitting && (<button
                        type="submit"
                        className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-xl font-bold text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
                        Subscribe
                    </button>)}
                    {isFormSubmitting && (
                        <button id="btn-processing" type="button"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center"
                                disabled>
                            <svg className="animate-spin h-5 w-5 text-white mr-2"
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Processing...
                        </button>)}
                </div>)}
                </div>
                {errors.emailAddress && <div className={'block mt-2'}>{errors.emailAddress.message}</div>}
                {apiErrorMessage && <div className={'block mt-2'}>An unexpected error occurred</div>}
            </form>

        </div>

    )
}