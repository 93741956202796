import {z} from "zod";

const optionalNameRegex: RegExp = /^[a-zA-Z '.]*$/;
//^ and $ ensure that the entire string matches the pattern.
//[a-zA-Z '.] matches any letter (uppercase or lowercase), space, apostrophe, or period.
//* allows zero or more occurrences of the characters in the set, which also allows an empty string.

export const ProspectMailingListRequestSchema = z.object({
    tenantUuid: z.string()
        .min(1,"Tenant ID is required")
        .max(50,"Tenant ID must be 50 characters or less")
        .regex( new RegExp(/^[a-zA-Z0-9-_]+$/), "Tenant ID can only include letters numbers, _ and -"),
    emailAddress: z.string()
        .min(3, "Email is required.")
        .max(320,"Email must be 320 characters or less.")
        .email("Please enter a valid email address."),
    mailListTypeCode: z.string()
        .min(1,"Mailing List Type Code is required")
        .max(30,"Mailing List Type Code must be 50 characters or less")
        .regex( new RegExp(/^[a-zA-Z0-9-_]+$/), "Mailing List Type Code can only include letters numbers, _ and -"),
    entryMethod : z.string()
        .min(1,"Entry Method Type Code is required")
        .max(50,"Entry Method Type Code must be 50 characters or less")
        .regex( new RegExp(/^[a-zA-Z0-9-_]+$/), "Entry Method Type Code can only include letters numbers, _ and -"),
    nameUnknownFlag : z.boolean(),
    firstName: z.string()
        .min(0, "First Name is not required.")
        .max(40,"First Name must be 40 characters or less.")
        .regex( new RegExp(optionalNameRegex), "First Name can only include letters and spaces."),
    lastName: z.string()
        .min(0, "Last Name is not required.")
        .max(40,"Last Name must be 40 characters or less.")
        .regex( new RegExp(optionalNameRegex), "Last Name can only include letters and spaces."),
    formErrors: z.string().optional()
})
