'use client'
import {Popover, Transition} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";

import {ChevronDownIcon} from "@heroicons/react/24/solid";

import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {ProductCategory} from "@/modules/classes/products/ProductCategories";
import {getProductCategories} from "@/modules/data/ProductCategoryData";
import Link from "next/link";
import Image from "next/image";
import {getFontAwesomeIconProps} from "@/modules/utilities/media/FontAwesomeUtilities";
import imageLoader from "@/modules/utilities/media/ImageUtilities";

const productCategories : Array<ProductCategory> = getProductCategories();

const resources = [
    {
        name: 'Life Insurance',
        description: 'Protection & financial security for your family and loved ones.',
        href: '/products/champaign-urbana-savoy/life-insurance',
    },
    {
        name: 'Commercial Insurance',
        description: 'Insurance for all your business needs.',
        href: '/products/champaign-urbana-savoy/commercial-insurance',
    },
    {
        name: 'SR-22',
        description: 'Need an SR-22 due to DUI, reckless driving and/or driving without insurance..',
        href: '/articles/illinois-indiana-sr22',
    },
    {
        name: 'Other',
        description: 'Get a quote on ATV, Boat, Golf Cart, Personal Umbrella, Mobile Home, RV, Trailer',
        href: '/quote/quick-quote?product=OTHER',
    },
    {
        name: 'Customer Service',
        description: 'Get your questions answered in our forums or contact support.',
        href: '/service/customer-service',
    },
    {   name: 'About Us',
        description: 'Learn about our history and how we have been servicing Champaign-Urbana & Central Illinois since 1955.',
        href: '/company/about-us'
    },
]

const mobileAddlLinks = [
    {
        name: 'SR22',
        description: 'Need an SR-22 due to DUI, reckless driving and/or driving without insurance..',
        href: '/articles/illinois-indiana-sr22',
    },
    {
        name: 'Customer Service',
        description: 'Get your questions answered in our forums or contact support.',
        href: '/service/customer-service',
    },
    {   name: 'About Us',
        description: 'Learn about our history and how we have been servicing Champaign-Urbana & Central Illinois since 1955.',
        href: '/company/about-us'
    },
]
function classNames(...classes : any) {
    return classes.filter(Boolean).join(' ')
}

export default function GlobalNavigationComponent() : JSX.Element {
    return (

        <header className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <Popover className="relative bg-white">
                <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50">
                    <div>
                        <a href="/" className="flex">
                            <span className="sr-only">Logo</span>
                            <Image
                                className="resize-none"
                                src="/images/brya-logo5.png"
                                alt="Brya Logo"
                                height={40}
                                width={225}
                                fill={false}
                                loader={imageLoader}
                            />
                        </a>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-bryaLogoBlue-50">
                            <span className="sr-only">Open menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                        <Popover.Group as="nav" className="flex space-x-10">
                            <Link href='/products/champaign-urbana-savoy/auto-insurance' className="text-base text-gray-500 hover:text-gray-900 no-underline hover:underline">
                                Auto
                            </Link>
                            <Link href='/products/champaign-urbana-savoy/home-renters-insurance' className="text-base text-gray-500 hover:text-gray-900 no-underline hover:underline">
                                Home
                            </Link>
                            <Link href='/products/champaign-urbana-savoy/motorcycle-insurance' className="text-base text-gray-500 hover:text-gray-900 no-underline hover:underline">
                                Motorcycle
                            </Link>
                            <Link href='/products/champaign-urbana-savoy/life-insurance' className="text-base text-gray-500 hover:text-gray-900 hidden lg:block no-underline hover:underline">
                                Life
                            </Link>
                            <Link href='/products/champaign-urbana-savoy/commercial-insurance' className="text-base text-gray-500 hover:text-gray-900 hidden lg:block no-underline hover:underline">
                                Commercial
                            </Link>

                            <Popover className="relative">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                open ? 'text-gray-900' : 'text-gray-500',
                                                'group bg-white inline-flex items-center text-base hover:text-gray-900 '
                                            )}
                                        >
                                            <span className="text-base text-gray-500 hover:text-gray-900 no-underline hover:underline" >More</span>
                                            <ChevronDownIcon
                                                className={classNames(
                                                    open ? 'text-gray-600' : 'text-gray-400',
                                                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-1"
                                        >
                                            <Popover.Panel className="absolute z-50 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                        {resources.map((item, index) => (
                                                            <a key={item.name} href={item.href} className={getPopupNavClass(index)}>
                                                                <p className="text-base font-medium text-gray-900">{item.name} </p>
                                                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>
                        </Popover.Group>
                        <div className="flex items-center md:ml-12">
                            <a href="/login" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                Login
                            </a>
                            <a
                                href="/quote/quick-quote"
                                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm md:text-xs lg:text-base font-medium text-white bg-bryaLogoBlue-50 hover:bg-indigo-700"
                            >
                                Get a Quote
                            </a>
                        </div>
                    </div>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel focus className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right me:hidden">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5 ">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <Image
                                            className="h-10 w-auto"
                                            src="/images/brya-logo5.png"
                                            alt="Workflow"
                                            loader={imageLoader}
                                            height={40}
                                            width={225}
                                            fill={false}
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-bryaLogoBlue-50">
                                            <span className="sr-only">Close menu</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <nav className="grid gap-6">
                                        {productCategories.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="-m-3 p-3 flex items-center rounded-lg border-gray-100 hover:bg-gray-300"
                                            >
                                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-bryaLogoBlue-50 text-white">
                                                    <FontAwesomeIcon icon={getFontAwesomeIconProps(item.faIconString)} className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <div className="ml-4 text-base font-medium text-gray-900 no-underline hover:underline">{item.name}</div>
                                            </a>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                            <div className="py-2 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    {mobileAddlLinks.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="text-base font-medium text-gray-900 hover:text-gray-700 rounded-lg border-gray-100 p-2  hover:bg-gray-300 no-underline hover:underline"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="mt-6">
                                    <a
                                        href="/quote/quick-quote"
                                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-bryaLogoBlue-50"
                                    >
                                        Get an Insurance Quote
                                    </a>
                                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                                        Existing customer?{' '}
                                        <a href="/login" className="text-indigo-600 hover:text-indigo-500">
                                            Login
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </header>
    )
}



function getPopupNavClass(index: number): string {
    let classes: string = "";
    classes = "-m-3 p-3 block rounded-md hover:bg-gray-300";

    if (index <2 ) {
        classes = "-m-3 p-3 block rounded-md hover:bg-gray-300 lg:hidden"
    }
    return classes
}